import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, Subject, tap } from 'rxjs';
import { catchError, concatMap, filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingLogin, AppRoutingSelect } from '../../app-routing.model';
import {
  AuthCode,
  AuthResult,
  LoginResult,
  queryParamKeyEntropy,
  queryParamKeyRedirectUrl,
  queryParamKeyToken,
} from './auth.model';
import { AuthProviderName } from '../../shared/models/environment';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './logger.service';
import { AuthProvider, OAuthProvider } from 'firebase/auth';
import { Auth, getRedirectResult, idToken, signInWithRedirect } from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import passwordEntropy from 'fast-password-entropy';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG } from '@movinmotion/conf-shared';
import constants from '../constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected readonly token$ = new BehaviorSubject<string>(null);
  protected passwordEntropy: number | null = null;

  protected readonly redirectUrl: Observable<URL>;
  protected readonly customRedirectUrl = new Subject<URL>();

  protected readonly providers: { [key in AuthProviderName]?: AuthProvider } = Object.entries(
    environment.providers,
  ).reduce((result, [name, { id, enabled }]) => {
    if (id && enabled) {
      result[name] = new OAuthProvider(id);
    }
    return result;
  }, {});

  private sharedEnv = inject(ENVIRONMENT_CONFIG);
  /**
   * RxJS operator that catch Auth error and always return an {@see AuthResult} (no exception is thrown when you apply this operator)
   */
  protected catchAuthError$: (source: Observable<any>) => Observable<AuthResult> = catchError(request => {
    const loginResult: LoginResult = request.error;
    const result: AuthResult = {
      code: AuthCode.unknown,
      error: loginResult.error,
    };
    switch (loginResult.error?.message.split(' ')[0]) {
      case 'auth/invalid-action-code':
      case 'INVALID_OOB_CODE':
        this.logger.info('Invalid login code');
        result.code = AuthCode.codeInvalid;
        break;
      case 'auth/wrong-password':
      case 'INVALID_PASSWORD':
      case 'INVALID_LOGIN_CREDENTIALS':
      case 'auth/invalid-login-credentials':
        this.logger.info('Invalid login credentials');
        result.code = AuthCode.failure;
        break;
      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        this.logger.info('Invalid login credentials');
        result.code = AuthCode.tooManyRequest;
        break;
      default:
        this.logger.error('Unknown error in Firebase Auth, see error: ', loginResult.error);
        break;
    }
    return of(result);
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    protected firebaseAuth: Auth,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    @Inject(DOCUMENT) protected document: Document,
    protected cookieService: CookieService,
    protected logger: LoggerService,
    protected translate: TranslateService,
    private http: HttpClient,
  ) {
    idToken(firebaseAuth)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(token => {
        this.token$.next(token);
      });
    // The redirect URL merge the one provided by query parameter or the custom one specify directly
    this.redirectUrl = merge(
      of(null), // Initialise with null to emit at least one value
      merge(
        this.customRedirectUrl.asObservable(),
        this.activeRoute.queryParams.pipe(
          map(params => {
            return params?.[queryParamKeyRedirectUrl]
              ? this.convertStringToUrl(params[queryParamKeyRedirectUrl])
              : null;
          }),
        ),
      ).pipe(filter(url => url !== null)), // Avoid not usable URL
    ).pipe(shareReplay(1)); // Allow to retrieve the last emitted value
  }

  /**
   * Sign in the user using the email and password provided
   *
   * @param email of the user
   * @param password of the user
   */
  signInWithEmailAndPasswordWrapper$(email: string, password: string): Observable<AuthResult> {
    this.passwordEntropy = passwordEntropy(password);
    return this.http
      .post<LoginResult>(`${this.sharedEnv.api.movinmotionBackend}/public/login/psswd`, {
        email,
        password,
      })
      .pipe(
        tap(loginResult => {
          if (loginResult.idToken) {
            this.token$.next(loginResult.idToken);
          }
        }),
        map(
          loginResult =>
            ({
              code: AuthCode.success,
              token: loginResult.idToken,
            }) as AuthResult,
        ),
        this.catchAuthError$,
      );
  }

  autoSignIn$(email: string, link: URL): Observable<AuthResult> {
    return this.http
      .post<LoginResult>(`${this.sharedEnv.api.movinmotionBackend}/public/login/oob`, {
        email,
        oobCode: link.searchParams.get('oobCode'),
      })
      .pipe(
        tap(loginResult => {
          if (loginResult.idToken) {
            this.token$.next(loginResult.idToken);
          }
        }),
        map(
          loginResult =>
            ({
              code: AuthCode.success,
              token: loginResult.idToken,
            }) as AuthResult,
        ),
        this.catchAuthError$,
      );
  }

  /**
   * Sing in the user using the provider specified (make a redirection)
   *
   * @param providerName to use
   */
  signInWithProvider$(providerName: AuthProviderName): Observable<void> {
    if (this.providers?.[providerName]) {
      const provider = this.providers[providerName] as OAuthProvider;
      if (providerName === AuthProviderName.francetravail) {
        provider.addScope('api_peconnect-individuv1');
      }
      return from(signInWithRedirect(this.firebaseAuth, provider));
    }
    const errorMsg = 'Provider "' + providerName + '" is not declared or missing, unable to sign in';
    this.logger.error(errorMsg);
    throw Error(errorMsg);
  }

  /**
   * Check if there is a response for authentication after a provider redirection {@see signInWithProvider}
   *
   * @return the authentication result if there is one, else null
   */
  signInWithProviderResult$(): Observable<AuthResult> {
    return from(getRedirectResult(this.firebaseAuth)).pipe(
      map(userCredential => {
        if (userCredential?.user) {
          return {
            code: AuthCode.success,
            userCredential,
          } as AuthResult;
        }
        return null;
      }),
      this.catchAuthError$,
    );
  }

  /**
   * Redirect the user following this rules:
   * - There is no token present (user is not logged), redirect to the login screen;
   * - There is no redirect URL provided (by query parameter or customRedirectUrl provided in parameter), redirect to the select app screen;
   * - There is a token and a redirect URL, redirect the current location to the redirect URL and append the token in query parameter {@see queryParamKeyToken};
   *
   * @param customRedirectUrl (optional) if provided, use this URL instead of the already known one.
   * There is two method to inform the service of the redirect URL:
   * - Provide in this method a custom redirect URL;
   * - Provide in the query parameter the redirect URL using the right key {@see queryParamKeyRedirectUrl}
   * @return if the authentication ended (true), else indicate that the user need to proceed more step to login
   */
  redirect$(customRedirectUrl?: string): Observable<boolean> {
    if (customRedirectUrl) {
      this.customRedirectUrl.next(this.convertStringToUrl(customRedirectUrl));
    }
    return combineLatest([this.token$.asObservable(), this.redirectUrl]).pipe(
      concatMap(([token, url]) => {
        //this.logger.debug('Call redirect with stored token: ', token);
        this.logger.debug('Call redirect with stored redirect URL: ', url);
        if (!token) {
          return from(this.router.navigate([AppRoutingLogin], { queryParamsHandling: 'merge' })).pipe(map(() => false));
        }
        if (!url || !constants.redirectionAllowedDomains.some(domain => url.host.endsWith(domain))) {
          return from(this.router.navigate([AppRoutingSelect], { queryParamsHandling: 'merge' })).pipe(
            map(() => false),
          );
        }
        url.searchParams.set(queryParamKeyToken, token);
        if (this.passwordEntropy) {
          url.searchParams.set(queryParamKeyEntropy, this.passwordEntropy.toString());
        }
        this.document.location.href = url.toString();
        return of(true);
      }),
    );
  }

  /**
   * Navigate to the redirect URL if provided, if it isn't this method fallbacks to the default behavior of {@link AuthService.redirect$}
   *
   * @returns True if the redirection worked properly, False otherwise
   */
  goToRedirectUrl$(): Observable<boolean> {
    return this.redirectUrl.pipe(
      first(),
      switchMap(url => {
        if (!url) {
          return this.redirect$();
        }
        this.document.location.href = url.toString();
        return of(true);
      }),
    );
  }

  /**
   * Changes on the redirect URL stored
   */
  redirectUrlChanged$(): Observable<URL> {
    return this.redirectUrl;
  }

  /**
   * Changes on the authentication token stored
   */
  tokenChanged$(): Observable<string> {
    return this.token$.asObservable();
  }

  /**
   * Manage automatically a sign in with a provider (handle redirection).
   * Just subscribe to this observable, that will provide a loading indicator and an authentication result if one come.
   * To trigger this authentication, there is two ways:
   *   - provide in the query param the provider name, {@see environment.queryParamsKeys.providerConnection} for the param value
   *   - call the {@see signInWithProvider} with the right provider
   */
  automaticProviderLogin$(): Observable<{ loading: boolean; authResult?: AuthResult }> {
    return this.signInWithProviderResult$().pipe(
      switchMap(authResult => {
        if (authResult && authResult.code === AuthCode.success) {
          return this.redirect$().pipe(
            map(() => ({
              loading: true,
              authResult,
            })),
          );
        } else if (!authResult) {
          return this.activeRoute.queryParamMap.pipe(
            switchMap(params => {
              if (params.has(environment.queryParamsKeys.providerConnection)) {
                const provider = params.get(environment.queryParamsKeys.providerConnection) as AuthProviderName;
                try {
                  return this.signInWithProvider$(provider).pipe(
                    map(() => ({
                      loading: true,
                    })),
                  );
                } catch (err) {
                  // Noop
                }
              }
              return of({
                loading: false,
              });
            }),
          );
        } else {
          return of({
            loading: false,
            authResult,
          });
        }
      }),
    );
  }

  /**
   * Convert the provided string to a valid URL. If the URL is not valid, return null (and log a console error).
   *
   * @param url to convert
   */
  private convertStringToUrl(url: string): URL | null {
    try {
      return new URL(url);
    } catch (err) {
      this.logger.error('Unable to get a valid URL from the one provided, see error:', err);
    }
    return null;
  }
}
