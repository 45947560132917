import { NgxLoggerLevel } from 'ngx-logger';

export enum MovinmotionApplication {
  core = 'core',
  accounting = 'accounting',
  worker = 'worker',
  social = 'social',
  talents = 'talents',
  odalie = 'odalie',
}

export enum AuthProviderName {
  myId = 'myId',
  tohero = 'tohero',
  movinmotion = 'movinmotion',
  deezer = 'deezer',
  francetravail = 'francetravail',
}

export enum Language {
  fr = 'fr',
  en = 'en',
}

export interface Environment {
  production: boolean;
  defaultLanguage: Language;
  logger: {
    level: NgxLoggerLevel;
  };
  useFirebaseEmulator: boolean;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  queryParamsKeys: {
    redirectUrl: string;
    token: string;
    entropy: string;
    resetPassword: string;
    changePassword: string;
    email: string;
    autoConnectUrl: string;
    language: string;
    display: string;
    providerConnection: string;
    movinmotionAuthError: string;
  };
  displayByAppHostEnabled: boolean;
  movinmotionAppUrls: { [key in MovinmotionApplication]: string };
  providers: { [key in AuthProviderName]: { id: string; enabled: boolean } };
  subscribeUrl: string;
  filmFranceSubscribeUrls: {
    worker: string;
    talents: string;
  };
  odalieSubscribeUrl: string;
  envName: string;
  sentryDsn: string;
  thirdParty: {
    mixpanel: {
      enabled: boolean;
    };
  };
}
